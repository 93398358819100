import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {GiftService} from 'src/app/layout/gift-detail/services/gift-detail.service';
import {ShareService} from '../../services';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-step-first',
  templateUrl: './step-first.component.html',
  styleUrls: ['./step-first.component.scss'],
})
export class StepFirstComponent implements OnInit, OnChanges {
  imageAsset = '../../../../../assets/images';
  phone: string;
  @Input() data: any;
  @Input() typeGift: any;
  @Input() typeCard: any;
  @Input() currentCampaign: any;
  @Output() eventEmmiter = new EventEmitter<any>();

  type: number;
  rows1 = [];
  rows2 = [];
  provider: string;
  sumited = false;
  message;
  disable = false;
  isLoading = false;

  constructor(
      private modalController: ModalController,
      private shareService: ShareService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isNotShow = this.currentCampaign?.owner?.id === environment.LOFOwnerId;
    // console.log("this.isLof", this.isNotShow)
    // console.log("this.currentCampaign?.owner?.id", this.currentCampaign?.owner?.id)
    }

  isNotShow = false;
  ngOnInit() {
    // this.disable = false;
    //
    // this.sumited = false;
    // this.message = 'Chưa nhập dữ liệu';
    // this.type = 99;

    console.log('ttttt', this.typeCard);
    console.log("data====>",this.data);
    this.getCard();
  }

  choiceType(type: number, provider: string) {
    this.sumited = false;
    this.type = type;
    this.provider = provider;
  }
  phoneChange(event) {
    if (event.target.value.trim()) {
      this.sumited = false;
    }
    event.target.value = event.target.value.replace(/[^0-9]*/g, '');
  }
  handleConfirmPhone() {
    this.sumited = true;
    // if (!this.phone && this.typeCard === 'TOPUP') {
    if (!this.phone && this.tabActive === 'TOPUP') {
      this.message = 'Chưa nhập số điện thoại';
      return;
    }
    // console.log(this.phone.length)
    if (this.phone?.length < 10 &&  this.tabActive !== 'PHONE_CARD') {
      this.message = 'Vui lòng nhập đúng số điện thoại';
      return;
    }
    if (!this.provider) {
      this.message = 'Chưa chọn nhà mạng';
      return;
    }
    this.isLoading = true;
    this.message = '';
    if (this.disable) {
      return;
    }
    this.disable = true;
    // this.phone = this.phone.toString().replace(/[^0-9]*/g, '');
    // if (this.phone && this.phone?.length) {
    // if (this.type && this.provider) {
    //   this.eventEmmiter.emit({phone: this.phone, serviceId: this.type, provider: this.provider, type_gift: this.tabActive});
    // }
    this.eventEmmiter.emit({phone: this.phone, serviceId: this.type, provider: this.provider, type_gift: this.tabActive});
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  tabActive: string = 'TOPUP'; /*PHONECARD*/
  onChangeTable(tab: string) {
    this.tabActive = tab;
    this.getCard();
  }

  getCard() {

    this.rows1 = [];
    this.rows2 = [];
    this.shareService.getListNetwork().subscribe(
        res => {
          if (res.statusCode === 200) {
            const data = res.data[this.tabActive === 'TOPUP' ? 'phoneTopup' : 'phoneCard'];
            // const data = res.data[this.typeCard === 'TOPUP' ? 'phoneTopup' : 'phoneCard'];
            if (data.length > 3) {
              data.map((item, i) => {
                // if (i <= 2) {
                //   this.rows1.push(item);
                //   return;
                // }
                this.rows1.push(item);
                // this.rows2.push(item);
              });
              return;
            }
            this.rows1 = data;
          }
        }
    );
  }
}
