import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() message: any;
  @Input() title: any;
  @Input() status: any;
  constructor(private modalController: ModalController) { }

  ngOnInit() {}


  closeModal() {
    this.modalController.dismiss();
  }

}
