import { LotteryGiftsComponent } from './layout/lottery-gifts/lottery-gifts.component';
import { GiftDetailVnpointsComponent } from './layout/vnpoints/gift-detail-vnpoints/gift-detail-vnpoints.component';
import { AppGiftExchangesComponent } from './layout/vnpoints/app-gift-exchanges/app-gift-exchanges.component';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CampaignDetailComponent} from './layout/campaign/campaign-detail/campaign-detail.component';
import {HistoryComponent} from './layout/campaign/history/history.component';
import {MyGiftComponent} from './layout/campaign/my-gift/my-gift.component';
import {LandingComponent} from './layout/landing/landing.component';
import {MyGiftAndHistoryComponent} from './layout/campaign/my-gift-and-history/my-gift-and-history.component';

const routes: Routes = [
  { path: '', redirectTo: 'luckywheel', pathMatch: 'full' },
  {
    path: 'luckywheel',
    loadChildren: () => import('./layout/luckywheel/luckywheel.module').then(m => m.LuckyWheelModule)
  },
  {
    path: 'accumulate-point',
    loadChildren: () => import('./layout/accumulate-point/accumulate-point.module').then(m => m.AccumulatePointModule)
  },
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'luckywheel/:target/:type/:phone'
  // }
  {
    path: 'gift-detail',
    loadChildren: () => import('./layout/gift-detail/gift-detail.module').then(m => m.GiftDetailModule)
  },
  {
    path: 'campaign/:id',
    component: CampaignDetailComponent
  },
  {
    path: 'campaign/:id/:businessId/my-gift-and-history',
    component: MyGiftAndHistoryComponent
  },
  {
    path: 'campaign/:id/history',
    component: HistoryComponent
  },
  // {
  //   path: 'campaign/:id/:businessId/my-gift',
  //   component: MyGiftComponent,
  //   data: {
  //     title: 'Quà của tôi'
  //   }
  // },
  {
    path: 'landing/:businessId',
    component: LandingComponent
  },
  {
    path: 'landing/:businessName/:businessId',
    component: LandingComponent
  },
  {
    path: 'landing-app/vnpoint-market',
    component: AppGiftExchangesComponent
  },
  {
    path: 'landing-app/vnpoint-market/detail/:id',
    component: GiftDetailVnpointsComponent
  },
  {
    path: 'gift-lottery/detail/:id',
    component: LotteryGiftsComponent
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
